<script setup lang="ts">
  import CompositeDefaultHero from './Partials/Hero/CompositeDefaultHero.vue'
  import CompositeBlogHero from './Partials/Hero/CompositeBlogHero.vue'
  import CompositeGradientHero from './Partials/Hero/CompositeGradientHero.vue'
  import ErrorDefaultHero from './Partials/Hero/ErrorDefaultHero.vue'

  const componentMap: ComponentMap = {
    'composite.default': CompositeDefaultHero,
    'composite.blog': CompositeBlogHero,
    'composite.gradient': CompositeGradientHero,
    'error.default': ErrorDefaultHero,
  }

  const heroData = defineProps<{
    data: HeroData
    type: string
  }>()

  const selectedComponent = computed(() => componentMap[heroData.type])
</script>

<template>
  <header
    v-if="heroData?.data"
    class="full-content cXenseParse"
    style="--grid-row-gap: 0"
  >
    <component :is="selectedComponent" :hero-data="heroData?.data" />
  </header>
</template>
